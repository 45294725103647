<template>
  <div class="container">
    <LoadingScreen v-if="isLoading" />
    <div v-else class="content-1">
<div class="header-1">
  <h1>admin<span>.</span></h1>
</div>
<div class="buttons-1">
  <router-link to="/">JSK Products</router-link>
  <router-link to="/websiteproducts">Website Products</router-link>
  <router-link to="/brandedcompare">Branded Compare</router-link>
  <router-link to="/mjcompare">Jewellery Compare</router-link>
  <router-link to="/unidproducts">Unidentified Products</router-link>
</div>
<div class="footer">
  <p>© | Marios Jewellers 2024</p>
</div>
    </div>
    <div class="content-2">
      <div class="tables">
        <div class="router">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateTime from './components/DateTime.vue';
import LoadingScreen from './components/LoadingScreen.vue';
import { mapState } from 'vuex';

export default{
    components: { DateTime, LoadingScreen },
    computed: {
    ...mapState(['isLoading']),
  },
}
</script>
<style scoped>
*{
  padding: 0 !important;
  margin: 0 !important;
}

a{
  text-decoration: none;
}
.container{
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.content-1{
  background-color: #00072d !important;
  height: 100vh;
  width: 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.header-1{
  height: 10%;
  width: 90%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.541);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.header-1 h1{
  color: white;
  font-weight: 200;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 45px;
}
span{
  color: red;
}
.header-1 img{
  height: 40%;
  width: 30%;
  display: inline-block;
  background: #fafafa;
  line-height: 3;
  padding: 20px !important;
  border-radius: 50%;
}
.buttons-1{
  height: 80%;
  width: 75%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  /* align-items: center; */
  text-align: left;
}
.buttons-1 a{
  text-decoration: none;
  color: rgba(204, 198, 198, 0.897);
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  margin-top: 20px !important; 
}
.filters{
  height: 53%;
  width: 90%;
  border-top: 1px solid rgba(255, 255, 255, 0.541);
}
.footer{
  height: 10%;
  width: 90%;
  border-top: 1px solid rgba(255, 255, 255, 0.541);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.514);
  font-size: 12px;
  letter-spacing: 1px;
}
.footer p{
  font-family: 'Manrope', sans-serif;
  font-size: 11px !important;
}
.content-2{
  background-color: #7b7b7b;
  width: 85%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.tables{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.router{
  height: 97%;
    width: 98%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    border: 1px solid grey;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.click a.router-link-exact-active {
  color: rgb(255, 255, 255);
}

.click a:hover {
  color: rgb(201, 200, 200);
  transition: 0.3s ease-in-out;

}
</style>
