<template>
  <div class="loading-screen">
    <h1>admin<span>.</span></h1>
    <div class="loading-text">
      <div class="text" v-if="showLoading">{{ loadingText }}</div>
      <div class="resource" v-if="showLoading">{{ loadedResourcesCount }}/{{ totalResourcesCount }} resources pulled</div>
      <div v-else class="other-text">{{ otherText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingText: {
      type: String,
      default: "please wait while your data is loading...", // Default loading text
    },
    otherText: {
      type: String,
      default: "Other content loaded.", // Default text when loading is complete
    },
  },
  data() {
    return {
      showLoading: true,
      loadedResourcesCount: 0,
      totalResourcesCount: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.updateLoadingProgress();
    }, 3000); // Update every 3 seconds
  },
  methods: {
    updateLoadingProgress() {
      const resources = window.performance.getEntriesByType('resource');
      this.totalResourcesCount = resources.length;
      this.loadedResourcesCount = resources.filter(item => item.initiatorType !== 'xmlhttprequest').length; // Filter out XHR
    },
  },
  computed: {
    dynamicLoadingText() {
      return this.showLoading ? this.loadingText : this.otherText;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId); // Clear the interval when the component is destroyed
  },
};
</script>

<style scoped>
.loading-screen {
  width: 100%;
  height: 100vh;
  background-color: #00072d; /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
h1{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 100px;
  color: white;
  margin-bottom: 10px !important;
}
span{
  color: red;
}
.loading-text{
  font-family: 'Comfortaa', sans-serif;
  font-size: 30px;
  color: white;
  display: flex;
  flex-direction: column;
}
.text{
  font-size: 40px;
  margin-bottom: 80px !important;
}
.resource{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: rgb(155, 152, 148);
}
.other-text{
  font-family: 'Comfortaa', sans-serif;
  font-size: 30px;
  color: white;
}

</style>
