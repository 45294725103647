import { createStore } from 'vuex';

export default createStore({
  state: {
    products: [],
    woocommerceProducts: [],
    error: null,
    isLoading: false, // Add isLoading state property
    selectedCategory: '', // Add selectedCategory state property for filtering by category
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
      console.log('Fetched products:', products);
    },
    setWooCommerceProducts(state, products) {
      state.woocommerceProducts = products.map(product => ({
        ...product,
        imageURL: product.images.length > 0 ? product.images[0].src : '', // Add the image URL
        category: product.categories.length > 0 ? product.categories[0].name : '', // Add category
        date_published: product.date_published || '', // Add date_published
        status: product.status || '', // Update to publish_status
        url: `https://mariosjewellers.com/product/${product.slug || product.id}`,
      }));
      console.log('Fetched WooCommerce products:', products);
    },
    setError(state, error) {
      state.error = error;
      console.error('Error fetching data:', error);
    },
    setLoading(state, isLoading) { // Mutation to set loading state
      state.isLoading = isLoading;
    },
    setSelectedCategory(state, category) { // Mutation to set selected category
      state.selectedCategory = category;
    },
  },
  actions: {
    async fetchData({ commit, state }) {
      if (state.products.length === 0 || state.woocommerceProducts.length === 0) {
        try {
          commit('setLoading', true); // Set loading state to true before fetching data

          // Fetch data from the provided URL
          const response1 = await fetch('https://marios.myjsk.com/jskdplay/inventory.json');

          if (!response1.ok) {
            throw new Error(`Failed to fetch data. Status: ${response1.status}`);
          }

          const data1 = await response1.json();
          commit('setProducts', data1);

          // Fetch data from the WooCommerce REST API with optimized query parameters
          const response2 = await fetch(`https://mariosjewellers.com/wp-json/wc/v3/products?per_page=100&fields=id,name,sku,price,stock_status,images,categories`, {
            headers: {
              Authorization: 'Basic ' + btoa('ck_9aeab18292693f1243a7ec14619668a5259fe027:cs_cc46f2ee0f930d2c7760d0438e4901733f5cbb5a'),
            },
          });

          if (!response2.ok) {
            throw new Error(`Failed to fetch WooCommerce data. Status: ${response2.status}`);
          }

          const data2 = await response2.json();
          commit('setWooCommerceProducts', data2);
        } catch (error) {
          commit('setError', error.message);
        } finally {
          commit('setLoading', false); // Set loading state to false after fetching data
        }
      }
    },
  },
  getters: {
    products: state => state.products,
    woocommerceProducts: state => state.woocommerceProducts,
    error: state => state.error,
    isLoading: state => state.isLoading, // Add getter for loading state
    selectedCategory: state => state.selectedCategory, // Add getter for selected category
  },
});
