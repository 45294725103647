import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LoadingScreen from './components/LoadingScreen.vue'; // Import the LoadingScreen component

(async () => {
  try {
    // Create the Vue app instance with the Vuex store and router
    const app = createApp(App);

    // Use the Vuex store and router
    app.use(store).use(router);

    // Mount the LoadingScreen component first to show the loading screen
    const loadingInstance = createApp(LoadingScreen);
    const loadingElement = document.createElement('div');
    document.body.appendChild(loadingElement);
    loadingInstance.mount(loadingElement);

    // Fetch regular products
    const response1 = await fetch('https://marios.myjsk.com/jskdplay/inventory.json');

    if (!response1.ok) {
      throw new Error(`Failed to fetch data. Status: ${response1.status}`);
    }

    const data1 = await response1.json();

    // Fetch WooCommerce products
    const allWooCommerceProducts = [];
    let page = 1;

    while (true) {
      const response2 = await fetch(`https://mariosjewellers.com/wp-json/wc/v3/products?page=${page}`, {
        headers: {
          Authorization: 'Basic ' + btoa('ck_9aeab18292693f1243a7ec14619668a5259fe027:cs_cc46f2ee0f930d2c7760d0438e4901733f5cbb5a'),
        },
      });

      if (!response2.ok) {
        throw new Error(`Failed to fetch WooCommerce data. Status: ${response2.status}`);
      }

      const data2 = await response2.json();
      allWooCommerceProducts.push(...data2);

      // Check if there are more pages
      const totalPages = Number(response2.headers.get('X-WP-TotalPages'));
      if (page >= totalPages) break;

      page++;
    }

    // Initialize the Vuex store with the fetched products
    store.commit('setProducts', data1);
    store.commit('setWooCommerceProducts', allWooCommerceProducts);

    // Mount the app instance after data is fetched
    app.mount('#app');

    // Remove the loading screen once the app is mounted
    loadingElement.parentNode.removeChild(loadingElement);
  } catch (error) {
    console.error('Error fetching data:', error);
    // Handle error or redirect to an error page
  }
})();