<template>
<p id="date"> {{ currentDate }} </p><!--DONT FUCK AROUND WITH THIS, IT WAS HARD-->
<p> {{ currentTime }}</p>
</template>

<script>
  export default{
    data() {
        return {
            currentDate: "",
            currentTime: "",
            showProductTable: false,
            isActive: false, // Track the active state of the button
            showJSKProducts: false,
            showCombinedProducts: false,
            showComparisonComponent: false,
        };
    },
    mounted() {
        // Call the method to update the date and time
        this.updateDateTime();
        // Update the date and time every second (or as needed)
        setInterval(() => {
            this.updateDateTime();
        }, 1000);
    },
    methods: {
        updateDateTime() {
            // Get the current date and time using JavaScript's Date object
            const now = new Date();
            // Format the date and time
            // Display the full name of the month
            this.currentDate = `${now.toLocaleString("default", {
                weekday: "long",
            })}, ${now.getDate().toString().padStart(2, "0")} ${now.toLocaleString("default", { month: "long" })}`;
            // Determine whether it's morning or afternoon/evening
            const period = now.getHours() >= 12 ? "PM" : "AM";
            // Format the time with AM/PM
            const hours = now.getHours() % 12 || 12;
            this.currentTime = `${hours.toString().padStart(2, "0")}:${now
                .getMinutes()
                .toString()
                .padStart(2, "0")} ${period}`;
        },
    },
}
</script>
<style scoped>

p{
  color: white;
  font-size: 16px;
  margin-left: 5px !important;
  font-family: 'Comfortaa', sans-serif;
  }
</style>